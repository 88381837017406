import { createAsync, useParams } from '@solidjs/router';
import { Container, Details, Heading, HorizontalRule, Page, Picture, Section, Summary } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { Show, Suspense } from 'solid-js';
import { createContentfulRequest } from '../../../content/[model]/_client';
import { RichText } from '../../../../components/rich-text';

export default function ArticlePreview() {
	const params = useParams<{ slug: string; type: string }>();
	const article = createAsync(async () => getArticle(params.slug), { deferStream: true });

	return (
		<Container size="small">
			<Page>
				<Title>Article Preview | Previews | Troon</Title>
				<Suspense>
					<Show when={article()?.fields}>
						{(article) => (
							<>
								<Heading as="h1">{article().title}</Heading>

								<Show when={article().heroImage}>
									{(hero) => (
										<Picture
											preload
											src={hero().fields.file?.url}
											width={1024}
											height={576}
											sizes="(min-width: 1440px) 50vw, 95vw"
											class="w-full sm:rounded"
											alt={hero().fields.description ?? hero().fields.title ?? ''}
											crop="face"
										/>
									)}
								</Show>

								<Section>
									<RichText document={article().content} />
								</Section>

								<HorizontalRule />
								<div class="rounded bg-neutral-100 p-8">
									<Details>
										<Summary>Show debug data</Summary>
										<pre class="overflow-scroll">{JSON.stringify(article(), null, 2)}</pre>
									</Details>
								</div>
							</>
						)}
					</Show>
				</Suspense>
			</Page>
		</Container>
	);
}

const getArticle = createContentfulRequest('articles');
